import React from 'react';

export const NhaTrang = {
    "name": "Nha Trang",
    "link": "nha-trang",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50379760693_04e099e56e_t.jpg",
    "image": "https://live.staticflickr.com/65535/50379760693_0d7f727d34_o.jpg",
    "caption": "Beaches, Water Sports and Nightlife",

    "description": "Welcome to Nha Trang, Vietnam's number 1 resort town. Here you will find everything you need for a summer vacation retreat, with its long, white sandy beaches that stretch for miles, a blue dazzling ocean calling for a swim, a multitude of water sports you can try and frenetic nightlife to put you moving after a day of sunbathing. Even if you don't fancy spending all your day on the beach, Nha Trang still has plenty other options to offer, with shopping, cooking classes, theaters and hiking experiences.",

    "nrDays": <p>
            Being a resort town, the time you spend here will depend on how much you love beaches. Some people stay here a whole week to enjoy their summer vacations.
            Others are here only one night a move on to the next town on the map.
            <br/><br/>
            To get a general feeling for the city, and still have time to enjoy the beaches and do some activities, we recommend you spend from <b>2 to 3 days</b>.
        </p>,

    "whenVisit": <p>
            Nha Trang is a tropical city, with <b>two seasons</b>. The rainy season runs from <b>July to December</b>, with the months of <b>July</b> and <b>August</b> being the hottest months of the year. If you are coming to sunbath on the beach, then this is the best time to come.
            <br/><br/>
            On the other hand, the dry season runs from <b>January to June</b>, and this is the best time to visit if you prefer to walk around the place and discover hidden gems. Aim for the months of <b>March and April</b>, the chance of rain is small and the weather is still quite warm.
        </p>,

    "mobility": <p>
            If you are staying on a hotel near the beaches, then you will certainly not need to worry about transportation.
            If you are a bit dislocated, <b>buses</b> or <b>cycling</b> are your best bets, being cheap and reliable, and are everywhere on town.
            There are also a lot of <b>taxis</b> around town if you need to move to more dislocated places.
        </p>,

    "safety": <p>
            Nha Trang is a safe city to travel in. As in other places in Vietnam, scams are the most common issue that you will probably find here. Keep your personal belongings close to you, mostly at night.
        </p>,

    "itinerary": {
        "description": <p>
            While the perfect day in Nha Trang is spent on the beach, laying on the sand, taking the sun, going for a swim at the ocean, Nha Trang still has much more to offer.
            So, besides the beaches and water sports, if you have the time there are some places worth a visit in Nha Trang. We leave here a suggested itinerary of what you can do.
            </p>,
        "days": [
            {
                "description": <p>
                    You came here for the sunbathing, and that is what you should do on your first day.
                    The best beach is undoubtedly <b>Tran Phu Beach</b>, the one right on center of town.
                    All the fun and animation happens here, and you have a range of water sports to try.
                    <br/><br/>
                    After the sunset, enjoy the vibrant nightlife in town. You can start with a visit to the <b>Night Market</b>, where you can do all your shopping.
                    <br/><br/>
                    For those wanting to enjoy the nightlife to the fullest, it is time to hit the bars. Nightlife starts at the bars in downtown, as they close around midnight.
                    After that the people move to the coastal area, where there are many bars and clubs open until late. <b>Biet Thu Street</b> is specially known to have the most number of bars and is where they close the latest.
                    <br/><br/>
                    The most famous spot in town is probably <b>Nha Trang Sailing Club</b>. For other good bars, you also have the <b>Crazy Kim's</b> and <b>Guava</b>.
                </p>,
                "image": "https://live.staticflickr.com/65535/50502223573_275c9986fe_o.jpg",
                "caption": "Night Market in Nha Trang."
            },
            {
                "description": <p>
                    For the second day, you can visit some attractions around town. In the morning visit the <b>Po Nagar Temple</b>, if you are a fan of marine life, make sure to pay a visit to the <b>National Oceanographic Museum</b>.
                    <br/><br/>
                    On the afternoon, if you like to explore new places, you can go to the <b>Ba Ho Waterfalls</b>.
                    Otherwise, Nha Trang is also famous for it's <b>Hot Springs and Mud Bathings</b>, and there many places around town you can try it, one famous example is the <b>Thap Ba Hot Springs Centre</b>.
                </p>,
                "image": "https://live.staticflickr.com/65535/50502941221_d71e090825_o.jpg",
                "caption": "Nha Trang bay."
            },
            {
                "description": <p>
                    On the third day, you can visit some of the islands near the coast. There are companies organizing tours to visit the islands.
                    The most famous islands to visit are <b>Mun and Mot Islands</b>, with <b>Monkey Island</b> nearby also being a good option.
                </p>,
                "image": "https://live.staticflickr.com/65535/50502223533_044602a99e_o.jpg",
                "caption": "Beach on Mun Island."
            }
        ]
    },

    "highlights": [
        {
            "name": "Nha Trang Bay",
            "description": "The main attraction in Nha Trang. This is the central beach from where the city started to grow. Stretching to almost 8kms, this is where all the fun happens, and you will find a variety of activities here to entertain you during your vacations.",
            "image": "https://live.staticflickr.com/65535/50502941136_75d0ba2fa2_o.jpg"
        },
        {
            "name": "Po Nagar Temple",
            "description": "Dedicated to the goddess Yan Po Nagar, this a Buddhist temple located on a mountain, from where you have a great view to the city. Entrance fee is around 1.50$.",
            "image": "https://live.staticflickr.com/65535/50502941066_4c0088f8c5_o.jpg"
        },
        {
            "name": "National Oceanographic Museum",
            "description": "Build in 1922, this museum has a collection of more than 2.000 living ocean creatures and many rare fossils. Entrance fee is about 2$.",
            "image": "https://live.staticflickr.com/65535/50502223443_df135d768e_o.jpg"
        },
        {
            "name": "Ba Ho Waterfalls",
            "description": "This waterfalls are located a little far off from town, and you either need to catch a bus or taxi, or rent a bike to get there. Entrance ticket costs about 1$.",
            "image": "https://live.staticflickr.com/65535/50502223418_a1acb2cd4e_o.jpg"
        },
        {
            "name": "Island Hopping",
            "description": "For a bit of time out of Nha Trang, you can visit one of the many island around its shore. Those trips shouldn't cost much more than 10$, and the main islands you should try to visit and Mun Island, Mot Island and Monkey Island.",
            "image": "https://live.staticflickr.com/65535/50502940976_41be730d95_o.jpg"
        }
    ],

    "food": {
        "description": <p>
            There are some traditional dishes in Nha Trang you must try, namely:
            <br/><br/>
            <ul>
                <li><b>Bun Sua:</b> rice vermicelli with grilled fish and jellyfish;</li>
                <li><b>Banh Xeo:</b> traditional seafood pancakes;</li>
                <li><b>Banh Can:</b> mini rice pancakes;</li>
                <li><b>Goi Ca Mai:</b> deep herring salad;</li>
                <li><b>Nem Nuong:</b> grilled sausage.</li>
            </ul>
            One good place to taste Vietnamese food is the <b>Du Tuong Street</b>.
            This street is filled with food stands selling local cuisine dishes.
            <br/><br/>
            If you want to sample some great food, we leave some recommendations of places you should pay a visit.
            </p>,
        "restaurants": [
            {
                "name": "Nem Nuong Vu Thanh An",
                "description": "The best place in town to try Nem Nuong.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50503094582_ccced3340a_o.jpg"
            },
            {
                "name": "Lac Canh Restaurant",
                "description": "This is a grill your own food barbecue, Vietnamese style. The food is great, the portions are big and the prices are even better.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50502940906_4427451524_o.jpg"
            },
            {
                "name": "Sailing Club Nha Trang",
                "description": "A great place on front line of the beach, with an amazing ambience, with great food and drinks. The prices are a little higher here than most places around town.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50503094537_4994d481a0_o.jpg"
            }
        ]
    },

    "stay": {
        "description": <p>
                Being the number one resort town in the country, there is no shortage of places to stay here.
                For the best locations, you should search for accommodations in and around <b>Tran Phu Beach</b>.
                <br/><br/>
                Below we give you some recommendations of places to stay.
            </p>,
        "budget": [
            {
                "name": "Backpack Abode Hostel",
                "description": "This hostel located on the hearth of Nha Trang is perfect for people on a budget. Great ambience and atmosphere are what makes this place a gem.",
                "image": "https://live.staticflickr.com/65535/50502940856_e0350c498e_o.jpg",
                "link": "/"
            },
            {
                "name": "Bondi Backpackers Nha Trang",
                "description": "Another great option close to the beach. This accommodation has a pool on the top floor where you can enjoy a bath and meet other travelers.",
                "image": "https://live.staticflickr.com/65535/50502940831_dd3027bc6a_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Carpe DM Hotel",
                "description": "This hotel standing at 500 meters from the beach is good for a mid-budget stay. Rooms are clean and comfortable.",
                "image": "https://live.staticflickr.com/65535/50502223253_225b11ed46_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Royal Beach Boton Blue Hotel & Spa",
                "description": "Right in front of the beach of Nha Trang, this is the perfect place for beach lovers. It has an exterior pool on the top floor with great views for the ocean. The rooms are amazing, very spacious, clean and comfortable.",
                "image": "https://live.staticflickr.com/65535/50503094442_98e965bcf9_o.jpg",
                "link": "/"
            },
            {
                "name": "Hotel Novotel Nha Trang",
                "description": "Located on the main street of Nha Trang, in front of the beach. This is another good option for those seeking a good summer vacation.",
                "image": "https://live.staticflickr.com/65535/50503094387_cb59d4d516_o.jpg",
                "link": "/"
            }
        ]
    }
}
